export function initFacebookSdk() {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    if (typeof window !== "undefined") {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.NEXT_PUBLIC_META_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v15.0",
        });
      };
      console.log("fb init");
    }
    // load facebook sdk script
    if (typeof document !== "undefined") {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
      console.log("fb script");
    }
  });
}

export const loginFB = async (setUserData, setError) => {
  // Launch Facebook login

  if (window.FB) {
    await window.FB.login(
      await function (response) {
        if (response.authResponse) {
          const userData = {
            id: response.authResponse.userID,
            accessToken: response.authResponse.accessToken,
          };
          setUserData(userData);
        } else {
          setError("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "pages_show_list,pages_messaging,pages_messaging_subscriptions,pages_manage_metadata",
      }
    );
  }
};

export const loginLeads = async (setUserData, setError) => {
  // Launch Facebook login

  if (window.FB) {
    console.log(process.env.NEXT_PUBLIC_META_APP_ID);
    await window.FB.login(
      await function (response) {
        if (response.authResponse) {
          const userData = {
            id: response.authResponse.userID,
            accessToken: response.authResponse.accessToken,
          };
          setUserData(userData);
        } else {
          setError("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope: "pages_show_list,leads_retrieval,pages_manage_metadata",
      }
    );
  }
};
