import * as t from '../types'

const main = (state = {
        step: 0,
        clients: [],
        config: {
            title: '',
            start_date: ''
          },
        selected: undefined,
        sirena_options: {
            interactionDate:{
                start: 1,
                startType: 'week',
                end: 2,
                endType: 'week',
                active: false,
                startDate: '',
                endDate: '',
              },
              creationDate:{
                start: 1,
                startType: 'week',
                end: 2,
                endType: 'week',
                active: false,
                startDate: '',
                endDate: '',
                type: 'exact'
              },
              state: {
                new: false,
                followUp: false,
                archived: false,
                unclaimed: false,
                isActive: false,
                text: ''
              },
              groups:[],
              agents:[],
              origins:[],
              reasons:[]
        },
        actions: [undefined]
}, action) => {
    switch(action.type){
        case t.SET_STEP:
            return {...state, 
                step: action.payload
            }
        case t.SET_CLIENTS:
            return {...state, 
                clients: action.payload
            }
        case t.SET_CONFIG:
            return {...state, 
                config: action.payload
            }
        case t.SET_ACTIONS:
            return {...state, 
                actions: action.payload
            }
        case t.SET_SELECTED:
            return {...state, 
                selected: action.payload
            }

        case t.SET_SIRENA_OPTIONS:
            return {...state, 
                sirena_options: action.payload
            }
        case t.LOGOUT:
            return main(undefined,{type: 'none'})
            break;
        default:
            return state
    }
}

export default main;