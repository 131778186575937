import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { config } from "../../../httpConfig";
import { template } from "lodash";

export const getMedium = (action) => {
  switch (action) {
    case "facebook":
      return "fb";
    case "instagram":
      return "ig";
    case "whatsapp":
      return "wpp";
    default:
      return action;
  }
};

export const getUsesGracePerios = (action) => {
  switch (action) {
    case "fb":
    case "ig":
    case "wpp":
      return true;
    default:
      return false;
  }
};

export const useChannels = (activeChannel, prospect?, contactId?) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  const gracePeriodOpen = useMemo(() => {
    if (prospect == null || !activeChannel) return false;
    if (getUsesGracePerios(activeChannel.type) == false) return true;
    var medium = activeChannel.type;

    const lastDayInteractions = prospect.interactions.filter(
      (i) =>
        i.type == "channelMessage" &&
        i.content.medium == medium &&
        i.content.origin == "contact" &&
        moment().diff(moment(i.createdAt), "hours") < 24
    );

    return lastDayInteractions.length > 0;
  }, [prospect, activeChannel]);

  useEffect(() => {
    if (prospect == null) return () => {};
    let medium = activeChannel?.type;
    let mediumInteractions = prospect.interactions.filter(
      (i) => i.type == "channelMessage" && i.content.medium == medium
    );
    if (mediumInteractions.length > 0 && !gracePeriodOpen) {
      var channelId =
        mediumInteractions[mediumInteractions.length - 1].content.channel;

      axios
        .get(
          `${process.env.API}/channels/${channelId}/templates?contactId=${contactId}`,
          config()
        )
        .then((res) => {
          const processedTemplates = res.data.filteredTemplates.map(
            (template, i) => {
              return {
                ...template,
                placeholderValues: res.data.placeholderValues[i],
              };
            }
          );
          setTemplates(processedTemplates);
        });
    }
  }, [gracePeriodOpen, activeChannel]);

  return {
    selectedTemplate,
    setSelectedTemplate,
    templates,
    gracePeriodOpen,
  };
};
