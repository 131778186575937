import fetch from "isomorphic-fetch";
import { Contact } from "../../interfaces/crm/contact";
import { getCookie } from "../auth";
import axios from "axios";
import { useMutation } from "react-query";
import mixpanel from "mixpanel-browser";
import { getMedium } from "../../components/channels/hooks/useChannels";

const API = process.env.API;
const API_NEXT = process.env.API_NEXT
const API_WPP = 'https://api.getventia.com'

export const create = (contact: Contact) => {
  return fetch(`${API}/crm/contact`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(contact),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const whatsappIsOnline = () => {
  return fetch(`${API_WPP}/crm/whatsapp/online`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const WMPIsOnline = () => {
  return fetch(`${API_NEXT}/whatsappmanager/personal/start`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const desasociarWhatsapp = () => {
  return fetch(`${API_WPP}/crm/whatsapp/remove`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const WMPdesasociar = () => {

  return fetch(`${API_NEXT}/whatsappmanager/personal/reset`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
}

export const getFirstUnassigned = (origin) => {
  return fetch(
    `${API}/contacts/unassigned${origin ? `?origin=${origin}` : ""}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUnassignedCount = () => {
  return fetch(`${API}/contacts/unassigned/count`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const fetchContactsMarkers = (body, configQuery) => {
  let querystring: any = [];
  Object.keys(configQuery).map((k) => {
    if ((configQuery[k] || configQuery[k] == 0) && configQuery[k][0] != "all")
      return querystring.push(`${k}=${encodeURI(configQuery[k])}`);
  });
  return fetch(`${API}/crm/contacts/map?${querystring.join("&")}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendChannelMessage = async (
  id,
  action: string,
  message,
  template,
  channelid
) => {
  console.log(template);
  return fetch(`${API_WPP}/crm/contact/${id}/channelMessage`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      message: message,
      tag: template?.tag ?? undefined,
      template: template,
      medium: getMedium(action),
      channelid,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendChannelMultimediaMessage = async (
  formdata: any,
  id: string
) => {
  return fetch(`${API_WPP}/crm/contact/${id}/channelMessage/multimedia`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(formdata),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendUnofficialWhatsappMessage = async (id, text) => {
  return fetch(`${API_WPP}/crm/whatsapp/${id}/send`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      text,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendUnofficialServerlessWhatsappMessage = async (id, text) => {
  return fetch(`${API_NEXT}/crm/contact/${id}/personalwhatsapp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      text,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendUnofficialWhatsappMultimediaMessage = async (
  formdata: any,
  id: string
) => {
  return fetch(`${API_WPP}/crm/whatsapp/${id}/send/multimedia`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(formdata),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const sendServerlessWhatsappMultimediaMessage = async (
  formdata: any,
  id: string
) => {
  return fetch(`${API_NEXT}/crm/contact/${id}/personalwhatsapp/multimedia`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(formdata),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendQRUnofficialWhatsappMessage = async (id, channelid, text) => {
  return fetch(`${API_WPP}/crm/channel/qrwhatsapp/${channelid}/send/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      text,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

interface mutateVisit {
  id: string;
  visit: { state: string; comment: string; tag: string };
}
export const useCreateVisit = () => {
  return useMutation(({ id, visit }: mutateVisit) => {
    return fetch(`${API}/crm/contact/${id}/visit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
      body: JSON.stringify({
        via: "web",
        ...visit,
      }),
    })
      .then(async (response) => {
        return response.json();
      })
      .catch((err) => err);
  });
};

export const archive = (id, reason) => {
  return fetch(`${API}/crm/contact/${id}/archiving`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      reason,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const uploadContacts = (body) => {
  return axios.post(`${API}/crm/uploadContacts`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  });
};

export const uploadContactPreview = (form: FormData) => {
  return axios.post(`${API}/crm/uploadContactsPreview`, form, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  });
};

export const remind = (id, reminderDate) => {
  return fetch(`${API}/crm/contact/${id}/reminder`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      reminderDate,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const cancelreminder = (id) => {
  return fetch(`${API}/crm/contact/${id}/cancelreminder`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const transfer = (id, to) => {
  return fetch(`${API}/crm/contact/${id}/transfer`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      to,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const transferMasive = (from, to) => {
  return fetch(`${API}/crm/interaction/transfermasive`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      to,
      from,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const note = (id, body, isWhatsapp) => {
  return fetch(`${API}/crm/contact/${id}/note`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      body,
      channel: isWhatsapp ? "whatsapp" : undefined,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const callPhone = (id: string, phone: string) => {
  return fetch(`${API}/crm/contact/${id}/phonecall`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      phone,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const closeCallPhone = (
  id: string,
  interactionId: string,
  state: "calling" | "done" | "cancel" | "voicemail"
) => {
  return fetch(`${API}/crm/contact/${id}/closephonecall/${interactionId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({
      via: "web",
      state,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const update = (id, contact: Contact) => {
  return fetch(`${API}/crm/contact/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(contact),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const get = (id) => {
  return fetch(`${API}/crm/contact/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getInteractionsContact = (id, date) => {
  return fetch(`${API}/crm/contact/${id}/chatsfrom/${date}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const deleteContacts = (list) => {
  return fetch(`${API}/crm/deleteContacts`, {
    method: "POST",
    body: JSON.stringify(list),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const transferToInbox = (list) => {
  return fetch(`${API}/crm/transferInbox`, {
    method: "POST",
    body: JSON.stringify(list),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};



export const getContactList = (
  type,
  search = "",
  limit = 10,
  page = 0,
  lastDate: Date | false = false,
  archiveFilter: string,
  extraFilters: { [filter: string]: string }
) => {
  let useArchiveFilter = "";
  if (type == "closed" && archiveFilter != "all")
    useArchiveFilter = `&archivingReason=${archiveFilter}`;
  Object.keys(extraFilters).forEach((key) => {
    if (extraFilters[key] === "") {
      useArchiveFilter += `&no_${key}=true`;
    } else if (extraFilters[key] === undefined) {
    } else {
      useArchiveFilter += `&${key}=${extraFilters[key]}`;
    }
  });

  return fetch(
    `${API}/crm/contact/withLastInteractions?listType=${type}${useArchiveFilter}&limit=${limit}&page=${page}${lastDate && type == "reminder"
      ? "&gtReminderDate="
      : "&ltLastInteractionDate="
    }${lastDate ? lastDate : ""}${search && search != "" ? `&search=${search}` : ``
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getContactAvanced = (
  search = "",
  limit = 10,
  page = 0,
  filters
) => {
  let filterString = "";
  if (
    filters.origin &&
    !filters.origin.includes("all") &&
    filters.origin.length > 0
  )
    filterString += "&origin=" + encodeURIComponent(filters.origin.join(","));
  if (
    filters.archivingReason &&
    !filters.archivingReason.includes("all") &&
    filters.archivingReason.length > 0
  )
    filterString +=
      "&archivingReason=" +
      encodeURIComponent(filters.archivingReason.join(","));
  if (
    filters.status &&
    !filters.status.includes("all") &&
    filters.status.length > 0
  )
    filterString += "&estado=" + encodeURIComponent(filters.status.join(","));
  if (filters.user && !filters.user.includes("all") && filters.user.length > 0)
    filterString += "&agents=" + encodeURIComponent(filters.user.join(","));
  if (filters.sortModel && filters.sortModel.length > 0) {
    if (filters.sortModel[0].sort == "desc") {
      filterString +=
        "&sort=" + encodeURIComponent("-" + filters.sortModel[0].field);
    } else {
      filterString +=
        "&sort=" + encodeURIComponent("+" + filters.sortModel[0].field);
    }
  }
  if (filters.createdAt && filters.createdAt.gt)
    filterString += "&gtCreatedAt=" + filters.createdAt.gt;
  if (filters.createdAt && filters.createdAt.lt)
    filterString += "&ltCreatedAt=" + filters.createdAt.lt;

  if (filters.lastInteraction && filters.lastInteraction.gt)
    filterString += "&gtLastInteraction=" + filters.lastInteraction.gt;
  if (filters.lastInteraction && filters.lastInteraction.lt)
    filterString += "&ltLastInteraction=" + filters.lastInteraction.lt;

  return fetch(
    `${API}/crm/contact/advanced?limit=${limit}&page=${page}${filterString}${search && search != "" ? `&search=${encodeURIComponent(search)}` : ``
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + getCookie("token"),
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const downloadContact = (search = "", limit = 10, page = 0, filters) => {
  let filterString = "";
  if (
    filters.origin &&
    !filters.origin.includes("all") &&
    filters.origin.length > 0
  )
    filterString += "&origin=" + encodeURIComponent(filters.origin.join(","));
  if (
    filters.archivingReason &&
    !filters.archivingReason.includes("all") &&
    filters.archivingReason.length > 0
  )
    filterString +=
      "&archivingReason=" +
      encodeURIComponent(filters.archivingReason.join(","));
  if (
    filters.status &&
    !filters.status.includes("all") &&
    filters.status.length > 0
  )
    filterString += "&estado=" + encodeURIComponent(filters.status.join(","));
  if (filters.user && !filters.user.includes("all") && filters.user.length > 0)
    filterString += "&agents=" + encodeURIComponent(filters.user.join(","));
  if (filters.sortModel && filters.sortModel.length > 0) {
    if (filters.sortModel[0].sort == "desc") {
      filterString +=
        "&sort=" + encodeURIComponent("-" + filters.sortModel[0].field);
    } else {
      filterString +=
        "&sort=" + encodeURIComponent("+" + filters.sortModel[0].field);
    }
  }
  if (filters.createdAt && filters.createdAt.gt)
    filterString += "&gtCreatedAt=" + filters.createdAt.gt;
  if (filters.createdAt && filters.createdAt.lt)
    filterString += "&ltCreatedAt=" + filters.createdAt.lt;

  return axios({
    url: `${API}/crm/contact/advanced?limit=${limit}&downloadExcel=true&page=${page}${filterString}${search && search != "" ? `&search=${encodeURIComponent(search)}` : ``
      }`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: "JWT " + getCookie("token"),
    },
  });
};

export const generateDownloadContact = (
  search = "",
  limit = 10,
  page = 0,
  filters
) => {
  let filterString = "";
  if (
    filters.origin &&
    !filters.origin.includes("all") &&
    filters.origin.length > 0
  )
    filterString += "&origin=" + encodeURIComponent(filters.origin.join(","));
  if (
    filters.archivingReason &&
    !filters.archivingReason.includes("all") &&
    filters.archivingReason.length > 0
  )
    filterString +=
      "&archivingReason=" +
      encodeURIComponent(filters.archivingReason.join(","));
  if (
    filters.status &&
    !filters.status.includes("all") &&
    filters.status.length > 0
  )
    filterString += "&estado=" + encodeURIComponent(filters.status.join(","));
  if (filters.user && !filters.user.includes("all") && filters.user.length > 0)
    filterString += "&agents=" + encodeURIComponent(filters.user.join(","));
  if (filters.sortModel && filters.sortModel.length > 0) {
    if (filters.sortModel[0].sort == "desc") {
      filterString +=
        "&sort=" + encodeURIComponent("-" + filters.sortModel[0].field);
    } else {
      filterString +=
        "&sort=" + encodeURIComponent("+" + filters.sortModel[0].field);
    }
  }
  if (filters.createdAt && filters.createdAt.gt)
    filterString += "&gtCreatedAt=" + filters.createdAt.gt;
  if (filters.createdAt && filters.createdAt.lt)
    filterString += "&ltCreatedAt=" + filters.createdAt.lt;

  return {
    url: `${API}/crm/contact/advanced?limit=${limit}&downloadExcel=true&page=${page}${filterString}${search && search != "" ? `&search=${encodeURIComponent(search)}` : ``
      }`,
    method: "GET",
    responseType: "blob", // important
    authorization: "JWT " + getCookie("token"),
  };
};


export const sendEmailToProspects = ({ body, contactId, idProvider }) => {
  const url = `${API_NEXT}/crm/contact/${contactId}/mail?idProvider=${idProvider}`

  return fetch(url, {
    method: 'POST',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "JWT " + getCookie("token"),
    },
    body: JSON.stringify(body)
  })
}

export const postFileToServer = ({ body, idProvider }) => {
  const url = `${API_NEXT}/nylas/file?idProvider=${idProvider}`

  return fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
    body
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const getThreadEmails = (threadId) => {
  const url = `${API_NEXT}/crm/contact/mail?threadId=${threadId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const getFiles = (providerId, filesId) => {
  const url = `${API_NEXT}/nylas/file?providerId=${providerId}&filesId=${filesId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
  })
    .then(res => res.json())
    .catch(e => console.log(e))
}

export const downloadFiles = (providerId, fileId) => {
  const url = `${API_NEXT}/nylas/fileDownload?idProvider=${providerId}&fileId=${fileId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": "JWT " + getCookie("token"),
    },
  })
    .then(res => res.blob())
    .catch(e => console.log(e))
}


