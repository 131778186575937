import { breadcrumbsClasses } from '@mui/material'
import * as t from '../types'

const main = (state = {
        name: "guest",
        open: false,
        usarAyer: false,
        users: [],
        usersGroup: [],
        selected_user: null,
        selected_group: null
}, action) => {
    switch(action.type){
        case t.SET_CLIENTS:
            return {...state, 
                step: action.payload
            }
            break;
        case t.SET_MENU_OPEN:
            return {...state, 
                open: action.payload
            }
        break;
        case t.SET_USAR_AYER:
            return {...state, 
                usarAyer: action.payload
            }
        break;
        case t.SET_INDEX_USERS:
            return {...state, 
                users: action.payload
            }
        break;
        case t.SET_SELECTED_USER:
            return {...state, 
                selected_user: action.payload
            }
        break;
        case t.SET_SELECTED_GROUP:
            return {...state, 
                selected_group: action.payload
            }
        break;
        case t.SET_USERS_GROUP:
            let result_groups = action.payload
            if(state.usersGroup && state.usersGroup.length > 0){
                result_groups = action.payload.map((group, i) => {
                    let g = JSON.parse(JSON.stringify(group))
                    if(g.users && state.usersGroup[i] && g._id == state.usersGroup[i]._id){
                        g.users = g.users.map((u) => {
                            
                            state.usersGroup[i].users.map((goal_item) => {
                                if(goal_item?._id == u._id){
                                    u.color = goal_item.color
                                }
                            })
                            return u
                        })
                    }
                    return g
                })
            }
            return {...state, 
                usersGroup: result_groups
            }
        break;
        case t.UPDATE_COLORS_USERS_GROUP:

            let result = state.usersGroup.map((group) => {
                let g = JSON.parse(JSON.stringify(group))
                if(g.users){
                    g.users = g.users.map((u) => {
                        
                        action.payload.map((goal_item) => {
                            if(goal_item?.user?._id == u._id){
                                u.color = goal_item.color
                            }
                        })
                        return u
                    })
                }
                return g
            })
            return {...state, 
                usersGroup: result
            }
        break;
        case t.LOGOUT:
            return main(undefined,{type: 'none'})
            break;
        default:
            return state
    }
}

export default main;