import {createStore,applyMiddleware} from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './reducers/rootReducer'
import main from './reducers/main'
import massive from './reducers/massive'
import crm from './slices/crm'
import report from './slices/report'
import thunk from 'redux-thunk';

export default configureStore({
    reducer: {massive, main, crm, report},
    middleware: [...getDefaultMiddleware()]
  })
  