export const SET_NAME = "SET_NAME";
export const SET_STEP = "SET_STEP";
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_ACTIONS = "SET_ACTIONS";
export const SET_CONFIG = "SET_CONFIG";
export const UPDATE_ACTION = "UPDATE_ACTION";
export const SET_SELECTED = "SET_SELECTED"
export const SET_SIRENA_OPTIONS = "SET_SIRENA_OPTIONS"
export const SET_MENU_OPEN = "SET_MENU_OPEN"
export const SET_SELECTED_USER = "SET_SELECTED_USER"
export const SET_INDEX_USERS = "SET_INDEX_USERS"
export const LOGOUT = "LOGOUT"
export const SET_USAR_AYER = "SET_USAR_AYER"
export const SET_USERS_GROUP = "SET_USERS_GROUP"
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP"
export const UPDATE_COLORS_USERS_GROUP = "UPDATE_COLORS_USERS_GROUP"