import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, getContactList, getInteractionsContact } from '../../actions/crm/contact'


const initialState: any = {
    activeContacts: [],
    reminderContacts: [],
    archiveContacts: [],
    allContacts: [],
    list: 0,
    contact: null,
    interactions: [],
    error: null,
    moreContacts: false,
    moreChats: false,
    replyMessage: false,
    action: 'select',
    requestsaleid: undefined,
    archiveFilter: 'all',
    iframeOpen: false,
    uploadStep: 0,
    uploadContacts: [],
    uploadedContacts: [],
    uploadContactUpdate: false,
    loadingList: false,
    activeChannel: null,
    fileNames: { test: "Ventia" }
}

interface fetchContactsInterface {
    name: string,
}

export const fetchContacts = createAsyncThunk<void, { name: string, search: string, page?: number, lastDate?: Date | false, addToList?: boolean, archiveFilter: string, extraFilters: { [filter: string]: string } }>(
    'crm/fetchContacts',
    async (query, { dispatch }) => {
        try {
            dispatch(setLoadingList(true))
            getContactList(query.name, query.search, 50, query.page ? query.page : 0, query.lastDate, query.archiveFilter, query.extraFilters).then((result) => {
                dispatch(setLoadingList(false))
                if (!result) return dispatch(setError("Error con la conexion al servidor"))
                if (result.error) return dispatch(setError(result.error))
                if (result.length < 50) {

                    dispatch(setMoreContacts(false))
                } else {
                    dispatch(setMoreContacts(true))
                }
                switch (query.name) {
                    case 'active':
                        if ((query.page && query.page > 0) || query.addToList) {
                            dispatch(addMultipleActiveContacts(result))
                        } else {
                            dispatch(setActiveContacts(result))
                        }
                        break;
                    case 'reminder':
                        if ((query.page && query.page > 0) || query.addToList) {
                            dispatch(addMultipleReminderContacts(result))
                        } else {
                            dispatch(setReminderContacts(result))
                        }
                        break;
                    case 'closed':

                        if ((query.page && query.page > 0) || query.addToList) {
                            dispatch(addMultipleArchiveContacts(result))
                        } else {
                            dispatch(setArchiveContacts(result))
                        }
                        break;

                    case 'all':

                        if ((query.page && query.page > 0) || query.addToList) {
                            dispatch(addMultipleAllContacts(result))
                        } else {
                            dispatch(setAllContacts(result))
                        }
                        break;
                    default:
                        dispatch(setError('El tipo ' + query.name + ' no existe'))
                }
            })
        } catch (err) {
            dispatch(setError('Error al actualizar la lista de contactos'))
        }


    }
)

export const fetchContact = createAsyncThunk<void, { id: string }>(
    'crm/getContact',
    async ({ id }, { dispatch }) => {
        try {
            get(id).then((p) => {
                if (!p) return dispatch(setError("Error al obtener info del contacto"))
                if (p.error) return dispatch(setError("Error al obtener info del contacto"))
                dispatch(setContact(p));
                if (p.interactions.length < 50) {
                    dispatch(setMoreChats(false))
                } else {
                    dispatch(setMoreChats(true))
                }
            });
        } catch (err) {
            dispatch(setError("Error al obtener info del contacto"))
        }
    }
)


export const fetchMoreInteractionsContact = createAsyncThunk<void, { id: string, date: string }>(
    'crm/getMoreInteractionsContact',
    async ({ id, date }, { dispatch }) => {
        try {
            getInteractionsContact(id, date).then((interactions) => {
                if (!interactions) dispatch(setError("Error a cargar el chat"))
                if (interactions.error) dispatch(setError("Error a cargar el chat"))
                dispatch(addInteractionsContact({ contact: id, interactions }));

                if (interactions.length < 25) {
                    dispatch(setMoreChats(false))
                } else {
                    dispatch(setMoreChats(true))
                }
            });
        } catch (err) {
            dispatch(setError("Error al obtener info del contacto"))
        }
    }
)


export const handleUpdate = createAsyncThunk<void, any>(
    'crm/handleUpdate',
    async (info, { dispatch, getState }) => {
        try {
            const state: any = getState()
            if (info.in == 'interaction' && state.crm.contact && info.contact == state.crm.contact._id) {
                if (info.type == 'new') {
                    dispatch(addInteraction(info.content))
                } else if (info.type == 'mod') {
                    dispatch(updateInteraction(info))
                }
            }
            if (info.in == 'contact' && info.type == 'new') {
                let content = info.content
                content.interactions = []
                dispatch(addActiveContact(content))
            }


            if (info.in == 'contact' && info.type == 'remove') {
                dispatch(removeContact(info.contact))
            }

            if (info.in == 'contact' && info.type == 'mod') {
                let content = info.content
                dispatch(modContact(content))
            }
            if (info.in == 'interaction') {
                dispatch(updateInteractionList(info))
            }

            if (info.in == 'outboundcall') {
                console.log('Llamaria con el siguiente info', info)
            }

        } catch (err) {
            dispatch(setError("Error al actualizar"))
        }
    }
)

export const crmSlice = createSlice({
    name: 'crm',
    initialState,
    reducers: {
        addActiveContact: (state, action) => {
            let indexAction = state.activeContacts.map(({ _id }) => _id).indexOf(action.payload._id)
            if (indexAction >= 0) {
                state.activeContacts.splice(indexAction, 1)
            }
            state.activeContacts = [action.payload, ...state.activeContacts]

        },
        successFetchContactsUpload: (state, action) => {
            state.uploadStep = 1
            state.uploadContacts = action.payload
        },
        goBackContactsUpload: (state) => {
            state.uploadStep--
        },
        goBackToContactsUpload: (state) => {
            state.uploadStep = 0
        },
        successCreateContactsUpload: (state, action) => {
            state.uploadStep = 2
            state.uploadedContacts = action.payload
        },
        addFileName: (state, action) => {
            if (!state.fileNames) {
                state.fileNames = { [action.payload.name]: action.payload.value }
            } else {
                state.fileNames[action.payload.name] = action.payload.value
            }
        },
        resetContactUpload: (state) => {
            state.uploadStep = 0
            state.uploadedContacts = []
            state.uploadContacts = []
        },
        addMultipleActiveContacts: (state, action) => {
            state.activeContacts = [...state.activeContacts, ...action.payload]
        },
        addMultipleReminderContacts: (state, action) => {
            state.reminderContacts = [...state.reminderContacts, ...action.payload]
        },
        addMultipleArchiveContacts: (state, action) => {
            state.archiveContacts = [...state.archiveContacts, ...action.payload]
        },
        addMultipleAllContacts: (state, action) => {
            state.archiveContacts = [...state.allContacts, ...action.payload]
        },
        setActiveContacts: (state, action) => {
            state.activeContacts = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
            if (state.archiveFilter != 'all' && action.payload != 3) {
                state.archiveFilter = 'all'
            }
        },
        setLoadingList: (state, action) => {
            state.loadingList = action.payload;
        },
        setUploadContactUpdate: (state, action) => {
            state.uploadContactUpdate = action.payload;
        },
        setArchiveFilter: (state, action) => {
            state.archiveFilter = action.payload
        },
        setReminderContacts: (state, action) => {
            state.reminderContacts = action.payload;
        },
        addReminderContacts: (state, action) => {
            state.reminderContacts = [action.payload, ...state.reminderContacts]
        },
        setArchiveContacts: (state, action) => {
            state.archiveContacts = action.payload
        },
        setAllContacts: (state, action) => {
            state.allContacts = action.payload
        },
        addArchiveContacts: (state, action) => {
            state.archiveContacts = [action.payload, ...state.archiveContacts]
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setContact: (state, action) => {
            state.contact = action.payload;
        },
        addInteraction: (state, action) => {
            let index = state.contact.interactions.map((i) => i._id).indexOf(action.payload._id)
            if (index < 0) {
                state.contact.interactions.unshift(action.payload)
            }
        },
        setAction: (state, action) => {
            state.action = action.payload
        },
        setActiveChannel: (state, action) => {
            state.activeChannel = action.payload.config
            state.action = action.payload.action
        },
        setRequestSaleId: (state, action) => {
            state.requestsaleid = action.payload
        },
        setMoreContacts: (state, action) => {
            state.moreContacts = action.payload
        },
        setMoreChats: (state, actions) => {
            state.moreChats = actions.payload
        },
        updateInteraction: (state, action) => {
            let index = state.contact.interactions.map((inter) => {
                return inter._id
            }).indexOf(action.payload.content._id)
            if (index >= 0) {
                state.contact.interactions[index] = action.payload.content
            }
        },
        updateInteractionList: (state, action) => {
            let indexAction = state.activeContacts.map(({ _id }) => _id).indexOf(action.payload.contact)
            let indexArchive = state.archiveContacts.map(({ _id }) => _id).indexOf(action.payload.contact)
            let indexReminder = state.reminderContacts.map(({ _id }) => _id).indexOf(action.payload.contact)
            if (indexAction >= 0) {
                state.activeContacts[indexAction].interactions.unshift(action.payload.content)
            } else if (indexArchive >= 0) {
                state.archiveContacts[indexArchive].interactions.unshift(action.payload.content)
            } else if (indexReminder >= 0) {
                state.reminderContacts[indexReminder].interactions.unshift(action.payload.content)
            }
        },
        removeContact: (state, action) => {
            let indexAction = state.activeContacts.map(({ _id }) => _id).indexOf(action.payload)
            let indexArchive = state.archiveContacts.map(({ _id }) => _id).indexOf(action.payload)
            let indexReminder = state.reminderContacts.map(({ _id }) => _id).indexOf(action.payload)
            if (indexAction >= 0) {
                state.activeContacts.splice(indexAction, 1)
            } else if (indexArchive >= 0) {
                state.archiveContacts.splice(indexArchive, 1)
            } else if (indexReminder >= 0) {
                state.reminderContacts.splice(indexReminder, 1)
            }
        },
        setIframeOpen: (state, action) => {
            state.iframeOpen = action.payload
        },
        handleRepyMessage: (state, action) => {
            state.replyMessage = action.payload
        },
        modContact: (state, action) => {
            let indexAction = state.activeContacts.map(({ _id }) => _id).indexOf(action.payload._id)
            let indexArchive = state.archiveContacts.map(({ _id }) => _id).indexOf(action.payload._id)
            let indexReminder = state.reminderContacts.map(({ _id }) => _id).indexOf(action.payload._id)
            if (indexAction >= 0) {

                if (action.payload.archivingReason && action.payload.archivingReason != '') {
                    state.archiveContacts.push({ ...state.activeContacts[indexAction], ...action.payload })
                    state.activeContacts.splice(indexAction, 1)

                } else if (action.payload.reminder && action.payload.reminder != '') {
                    // state.reminderContacts.push({...state.activeContacts[indexAction], ...action.payload})
                    // state.reminderContacts = state.reminderContacts.sort((a, b) => new Date(a.reminder).getTime() - new Date(b.reminder).getTime())
                    //Deberia agregar en el lugar donde va, sino error
                    state.activeContacts.splice(indexAction, 1)
                } else {
                    state.activeContacts[indexAction] = { ...state.activeContacts[indexAction], ...action.payload }
                }
            } else if (indexArchive >= 0) {

                if (!action.payload.archivingReason || action.payload.archivingReason == '') {
                    state.activeContacts.push({ ...state.archiveContacts[indexArchive], ...action.payload })
                    state.archiveContacts.splice(indexArchive, 1)
                } else {
                    state.archiveContacts[indexArchive] = { ...state.archiveContacts[indexArchive], ...action.payload }
                }
            } else if (indexReminder >= 0) {
                if (!action.payload.reminder || action.payload.reminder == '') {
                    state.reminderContacts.splice(indexReminder, 1)
                } else {
                    state.reminderContacts[indexReminder] = { ...state.reminderContacts[indexReminder], ...action.payload }
                }
            } else {
                //Capaz debaria agregarlo                
            }

            if (state.contact._id == action.payload._id) {
                ["remider", "archivingReason", "assignedGroups", "lockedBy", "lockedAt"].forEach((key) => {
                    if (!action.payload[key]) {
                        state.contact[key] = undefined
                    }
                })
                state.contact = { ...state.contact, ...action.payload }
            }
        },
        addInteractionsContact: (state, action) => {
            if (state.contact._id = action.payload.contact) {
                state.contact.interactions = [...state.contact.interactions, ...action.payload.interactions]
            }
        }
    }
})

export const { addMultipleArchiveContacts, addFileName, setUploadContactUpdate, setActiveChannel, setLoadingList, goBackContactsUpload, resetContactUpload, goBackToContactsUpload, successCreateContactsUpload, successFetchContactsUpload, setIframeOpen, setArchiveFilter, addMultipleAllContacts, setAllContacts, addMultipleReminderContacts, addActiveContact, removeContact, setMoreChats, addInteractionsContact, addMultipleActiveContacts, modContact, addArchiveContacts, addReminderContacts, setMoreContacts, updateInteractionList, setActiveContacts, setList, setReminderContacts, setArchiveContacts, setError, setContact, addInteraction, updateInteraction, setAction, setRequestSaleId, handleRepyMessage } = crmSlice.actions

export default crmSlice.reducer