import axios from "axios";
import cookie from "js-cookie";
import fetch from "isomorphic-fetch";
import { QueryClient } from "react-query";
const API = process.env.API;
const API_NEXT = process.env.API_NEXT

export const signin = (user) => {
  return fetch(`${API}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const upsertReportGrid = (config) => {
  return fetch(`${API}/user/config/report`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(config),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signin_push = (user) => {
  return fetch(`${API_NEXT}/login-push`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const register = (user) => {
  return fetch(`${API}/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const createUser = (user) => {
  return fetch(`${API}/user`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + getCookie('token')
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const update = (user) => {
  return fetch(`${API_NEXT}/register/update`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const updateById = (user) => {
  return fetch(`${API_NEXT}/user/${user._id}/update`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + getCookie('token')
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const deactivate = (user) => {
  return fetch(`${API}/register/deactivate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


export const userDeactivate = (user) => {
  return fetch(`${API}/user/${user}/deactivate`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + getCookie('token')
    },
  })
    .then(response => {
      return response.json()
    })
    .catch(err => console.log(err))
}

export const downloadUserListReport = () => {
  return axios(`${API}/groups/download/userlisthistory`, {
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Authorization': 'JWT ' + getCookie('token')
    },
  })
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export const deleteOne = (id) => {
  return fetch(`${API}/user/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signout = (next) => {
  let token = getCookie("token");
  removeCookie("token");
  removeCookie("app.sid");
  removeLocalStorage("user");
  const queryClient = new QueryClient();
  queryClient.clear()
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
  next();
  return fetch(`${API}/signout`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  })
    .then((response) => {
      console.log("signout success");
    })
    .catch((err) => console.log(err));
};

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 7,
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key);
  }
};

export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getLocalStorage = (key) => {
  if (process.browser) {
    return localStorage.getItem(key);
  }
};

export const removeLocalStorage = (key) => {
  if (process.browser) {
    localStorage.removeItem(key);
  }
};

export const authenticate = (data, next) => {
  setCookie("token", data.token);
  setLocalStorage("user", data.user);
  next();
};

export const isAuth = () => {
  if (typeof window !== 'undefined') {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (
        localStorage.getItem("user") &&
        localStorage.getItem("user") != undefined
      ) {
        if (
          !JSON.parse(localStorage.getItem("user")) ||
          !JSON.parse(localStorage.getItem("user")).group.nav
        ) {
          return signout(() => { });
        }
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return false;
      }
    }
  }
};

export const modifyUser = (field, value) => {
  if (typeof browser) {
    if (
      localStorage.getItem("user") &&
      localStorage.getItem("user") != undefined
    ) {
      let user_mod = JSON.parse(localStorage.getItem("user"));

      user_mod[field] = value;
      console.log("user_mod", user_mod[field]);
      localStorage.setItem("user", JSON.stringify(user_mod));
    }
  }
};

export const getCompany = () => {
  if (process.browser) {
    const cookieChecked = getCookie("token");
    if (cookieChecked) {
      if (
        localStorage.getItem("user") &&
        localStorage.getItem("user") != undefined
      ) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user.group.ancestors.length > 0) {
          return user.group.ancestors[0];
        } else {
          return user.group;
        }
      } else {
        return false;
      }
    }
  }
};

export const reloadtoken = (token) => {
  return fetch(`${API}/profile`, {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + (token ? token : getCookie("token")),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const forgotpassword = (email) => {
  return fetch(`${API}/forgot-password`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const resetpassword = (resetPasswordLink, newPassword) => {
  return fetch(`${API}/reset-password`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ resetPasswordLink, newPassword }),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const isGhostAdmin = () => {
  if (process.browser) {
    const cookieChecked = getCookie("admin_token");
    return !!cookieChecked;
  }
};

export const changeToGhostAdmin = (next) => {
  if (process.browser) {
    let adminToken = getCookie("admin_token");
    removeCookie("admin_token");

    const queryClient = new QueryClient();
    queryClient.clear()
    reloadtoken(adminToken).then((data) => {
      if (data.error) return "";
      authenticate(data, next);
    });
  }
};

export const getVarUrl = (url, user) => {
  let group = user.group;
  if (group && group.parent) {
    group = group.ancestors[0];
  } else if (!group) {
    return url
      .replace("%name%", user.name)
      .replace("%lastname%", user.lastname)
      .replace("%email%", user.email);
  }
  return url
    .replace("%name%", user.name)
    .replace("%lastname%", user.lastname)
    .replace("%email%", user.email)
    .replace("%group%", user.group.name)
    .replace("%company%", group.name);
};

export const getVarUrlEncode = (url, user) => {
  let group = user.group;
  if (group && group.parent) {
    group = group.ancestors[0];
  } else if (!group) {
    return url
      .replace("%name%", encodeURIComponent(user.name))
      .replace("%lastname%", encodeURIComponent(user.lastname))
      .replace("%email%", encodeURIComponent(user.email));
  }
  return url
    .replace("%name%", encodeURIComponent(user.name))
    .replace("%lastname%", encodeURIComponent(user.lastname))
    .replace("%email%", encodeURIComponent(user.email))
    .replace("%group%", encodeURIComponent(user.group.name))
    .replace("%company%", encodeURIComponent(group.name));
};

export const setChaportId = (id) => {
  return fetch(`${API}/chaport`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "JWT " + getCookie("token"),
    },
    body: JSON.stringify({ id }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
