import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import _ from 'lodash'
import { get, getContactList, getInteractionsContact } from '../../actions/crm/contact'


const initialState = {
    reports: {}
}



export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        initReport: (state, action) => {
            if(!state.reports[action.payload]) {
                state.reports[action.payload] = {
                    id: action.payload,
                    filters:[],
                    filters_metadata: [],
                    statesFilter: {}
                }
            } 
        },
        updateReportFilter: (state, action) => {
            let {id, filter, value} = action.payload
            if(!state.reports[id]) {
                state.reports[id] = {
                    id: action.payload,
                    filters:[],
                    filters_metadata: [],
                    statesFilter: {}
                }
            }
            if(state.reports[id].filters.length <= filter){
                let new_filters = [...state.reports[id].filters]
                for(let i = 0; i <= state.reports[id].filters.length - filter; i++){
                    new_filters.push(undefined)
                }
                new_filters[filter] = value
                state.reports[id].filters = new_filters
            }else{
                state.reports[id].filters[filter] = value
            }
            
        },
        updateReportFilterMetadata: (state, action) => {
            let {id, filter, value} = action.payload
            if(!state.reports[id]) {
                state.reports[id] = {
                    id: action.payload,
                    filters:[],
                    filters_metadata: [],
                    statesFilter:{}
                }
            }
            if(state.reports[id].filters_metadata.length <= filter){
                let new_filters = [...state.reports[id].filters_metadata]
                for(let i = 0; i <= state.reports[id].filters_metadata.length - filter; i++){
                    new_filters.push(undefined)
                }
                new_filters[filter] = value
                state.reports[id].filters_metadata = new_filters
            }else{
                state.reports[id].filters_metadata[filter] = value
            }
            
        },
        updateReportFilterMetadataExact:(state, action) => {
            let {id, filter, value, url, initObject} = action.payload
            if(!state.reports[id]) {

                state.reports[id] = {
                    id,
                    filters:[],
                    filters_metadata: [],
                    statesFilter: {...initObject, [url+"-"+filter]: value}
                }
            }else{
                state.reports[id].statesFilter[url+"-"+filter] = value
            }
            
        },
    }
})

export const {initReport, updateReportFilter, updateReportFilterMetadata, updateReportFilterMetadataExact} = reportSlice.actions

export default reportSlice.reducer